import request from './request';
/**
 * 获取用户信息
 * 
 */
export function getSellerInfo() {
	return request.get('store/merchant/info');
}

/**
 * 修改头像与店铺名称
 * 
 */
export function editSellerInfo(data) {
	return request.post('store/merchant/editInfo', data);
}

/**
 * 店铺实名认证
 * 
 */
export function applyVerify(data) {
	return request.post('store/merchant/applyVerify', data);
}

/**
 * 店铺修改密码
 * 
 */
export function setPassword(data) {
	return request.post('user/paypass/set', data);
}

/**
 * 店铺发送手机验证码（固定店主手机号）
 * 
 */
export function sendSms() {
	return request.post('user/paypass/sendsms');
}

/**
 * 设置用户分享
 * 
 */
export function userShare() {
	return request.post('user/share');
}

/**
 * 设置商家退货地址
 * 
 */
export function editSellerAddress(data) {
	return request.post('store/merchant/setAddress', data);
}

/**
 * 店铺上传商品 - 配置
 * 
 */
export function uploadProductConfig() {
	return request.get('store/merchant/product/config');
}

/**
 * 店铺商品列表
 * 
 */
export function manageProductList(data) {
	return request.get('store/merchant/product/lst', data);
}
// 获取商品管理的数字
export function manageProductNum() {
	return request.get('store/merchant/product/lst_filter')
}
/**
 * 店铺商品上下架
 * 
 */
export function switchStatusProduct(id, data) {
	return request.post('store/merchant/product/switchStatus/' + id, data);
}

/**
 * 店铺商品删除
 * 
 */
export function delProduct(id) {
	return request.post('store/merchant/product/delete/' + id);
}

/**
 * 店铺商品详情
 * 
 */
export function manageProductDetail(data) {
	return request.get('store/merchant/product/detail/' + data);
}

/**
 * 店铺商品详情
 * 
 */
export function manageProductDetailView(data) {
	return request.get('store/merchant/product/predetail/' + data);
}

/**
 * 店铺创建商品
 * 
 */
export function createdProduct(data) {
	return request.post('store/merchant/product/create', data);
}

/**
 * 店铺修改商品
 * 
 */
export function uploadProduct(id, data) {
	return request.post('store/merchant/product/update/' + id, data);
}

/**
 * 分销销售额
 * 
 */
export function distriSaleList(data) {
	return request.get('user/distri_sale_list', data);
}

/**
 * 累计收益
 * 
 */
export function brokerageRevenue() {
	return request.get('user/brokerage_revenue');
}

/**
 * 累计收益 - 商品佣金
 * 
 */
export function productBrokerageList(data) {
	return request.get('user/product_brokerage_list', data);
}

/**
 * 累计收益 - 招商佣金
 * 
 */
export function invitationMerchantBrokerageList(data) {
	return request.get('user/invitation_merchant_brokerage_list', data);
}

/**
 * 我邀请的人总信息
 * 
 */
export function invitationUserDetail() {
	return request.get('user/invitation_user_detail');
}

/**
 * 我邀请的人列表
 * 
 */
export function invitationUserList(data) {
	return request.get('user/invitation_user_list', data);
}

/**
 * 我的团队详情
 * 
 */
export function teamDetail() {
	return request.get('user/my_team/detail');
}

/**
 * 我的团队商户列表
 * 
 */
export function teamMerList(data) {
	return request.get('user/my_team/mer_list', data);
}

/**
 * 我的团队 - 商家详情
 * 
 */
export function merDetail(data) {
	return request.get('user/my_team/mer_detail', data);
}

/**
 * 我的团队 - 商家每天邀请列表
 * 
 */
export function merInvitationList(data) {
	return request.get('user/my_team/mer_invitation_list', data);
}

/**
 * 粉丝详情
 * 
 */
export function invitationFansInfo(data) {
	return request.get('user/invitation_fans/info', data);
}

/**
 * 粉丝订单列表
 * 
 */
export function invitationFansOrderList(data) {
	return request.get('user/invitation_fans/order_list', data);
}

/**
 * 用户佣金详情
 * 
 */
export function brokerageDetail() {
	return request.get('user/brokerage_detail');
}

/**
 * 用户佣金账单
 * 
 */
export function brokeragList(data) {
	return request.get('user/brokerage_list', data);
}

/**
 * 用户佣金详情
 * 
 */
export function paymentDetail() {
	return request.get('user/payment_detail');
}

/**
 * 用户佣金账单
 * 
 */
export function paymentList(data) {
	return request.get('user/payment_list', data);
}

/**
 * 用户佣金账单
 * 
 */
export function checkExtract(data) {
	return request.get('user/extract/check', data);
}

/**
 * 用户佣金账单
 * 
 */
export function extractCreate(data) {
	return request.post('user/extract/create', data);
}

/**
 * h5用户登录
 * @param data object 用户账号密码
 */
// export function loginH5(data) {
// 	return request.post("auth/login", data, {
// 		noAuth: true
// 	});
// }

/**
 * 图片链接转base64
 * 
 */
export function imgToBase(data) {
	return request.post('common/base64', data);
}


/**
 * 获取拍品列表
 * 
 */
export function getAuctionList(data) {
	return request.get('merchant/auction/list', data);
}

/**
 * 获取拍品竞拍中列表
 * 
 */
export function getAuctionStartList(data) {
	return request.get('merchant/auction/listStart', data);
}

/**
 * 店铺拍品下架
 * 
 */
export function offShelfAuction(id) {
	return request.post('merchant/auction/offShelf/' + id);
}

/**
 * 店铺拍品立即开始
 * 
 */
export function switchStartTimeStatus(id) {
	return request.post('merchant/auction/switchStartTimeStatus/' + id);
}
/**
 * 店铺拍品立即结拍
 * 
 */
export function switchEndTimeStatus(id) {
	return request.post('merchant/auction/switchEndTimeStatus/' + id);
}

/**
 * 店铺拍品创建
 * 
 */
export function createdAuction(data) {
	return request.post('merchant/auction/create', data);
}

/**
 * 店铺拍品修改
 * 
 */
export function editAuction(id, data) {
	return request.post('merchant/auction/update/' + id, data);
}
/**
 * 店铺拍品推荐
 * 
 */
export function editRecommendStatus(id, data) {
	return request.post('merchant/auction/editRecommendStatus/' + id, data);
}
/**
 * 店铺缴费
 * 
 */
export function sellPayMoney(data) {
	return request.post('store/merchant/payStoreDeposit', data);
}
/**
 * 店铺拍品详情
 * 
 */
export function manageAuctionDetail(id) {
	return request.get('merchant/auction/detail/' + id);
}
// 计算提现手续费
export function countCoMoney(data) {
	return request.get('user/extract/extractServiceCharge', data)
}

//  获取微信分类
export function getWxcategory() {
	return request.get('wechat/category/lst')
}

/**
 * 获取店铺实名认证信息
 * 
 */
export function getVerifyInfo() {
	return request.get('store/merchant/getVerifyInfo');
}

/**
 * 获取店铺开店选择的微信分类
 * 
 */
export function getMerWxcategory() {
	return request.get('wechat/category/merList');
}
/**
 * 获取店铺开店选择的分类
 * 
 */
export function getMerCategory() {
	return request.get('store/product/merchant/category/list');
}
// 批量创建商品
export function createMoreGoods(data) {
	return request.post('store/merchant/product/batchCreate', data);
}
// 批量创建拍品
export function createMoreAuction(data) {
	return request.post('merchant/auction/batchCreate', data);
}
// 获取草稿箱状态数字
export function getCaogaoNum() {
	return request.get('merchant/auction/list_filter')
}
// 立即开拍
export function setStartAuction(id) {
	return request.post(`merchant/auction/start_status/${id}`)
}
// 开通分销
export function openleague(data) {
	return request.post('store/merchant/switchDistributionStatus', data)
}
// 获取直播间拍品详情
export function getLiveAuction() {
	return request.get('merchant/auction/liveDetail')
}
// 获取直播间是否关联商品
export function getLiveIProduct() {
	return request.get('store/merchant/liveProductDetail', {}, {noVerify: true})
}
// 直播商品修改
export function editLiveIproduct(data) {
	return request.post('store/merchant/liveProductUpdate', data)
}
// 分类属性查询
export function getAttrList(data) {
	return request.get('store/product/category/attrlist', data)
}
// 可管理商户列表
export function getManageList() {
	return request.get("store/merchant/admin/merchantList")
}
// 子账号登录
export function loginManage(data) {
	return request.post('store/merchant/admin/login', data)
}
//  退出子账号
export function loginoutManage() {
	return request.get('store/merchant/admin/logout')
}
// 子账号列表
export function getAccountList() {
	return request.get('store/merchant/admin/getList')
}
// 添加子账号
export function addAccount(data) {
	return request.post('store/merchant/admin/create',data)
}
// 删除子账号
export function delAccount(id) {
	return request.post(`store/merchant/admin/delete/${id}`)
}
// 创建直播间
export function createLiveRoom(data) {
	return request.post('store/merchant/live_room/create', data)
}
// 修改直播间
export function editLiveRoom(id, data) {
	return request.post(`store/merchant/live_room/update/${id}`,data)
}
// 获取直播间详情
export function getLiveDetail(id) {
	return request.get(`store/merchant/live_room/detail/${id}`)
}
// 修改直播间状态
export function editLiveRoomStatus(id, data) {
	return request.post(`store/merchant/live_room/switch_status/${id}`, data)
}
// 获取直播间拍品
 export function getLiveRoomAuction(data) {
	 return request.get('auction/room_auction_list', data)
 }
 // 置顶商品
 export function switchZhiding(data) {
	 return request.post('store/merchant/live_room/product/switch_zhiding_status', data)
 }
 // 获取直播间商品
 export function getLiveRoomProduct(data) {
	 return request.get('store/product/room_list', data)
 }
 // 结束发送通知
 export function sendnotice(id) {
	 return request.post('merchant/auction/socketEndTimeStatus/'+id)
 }
 // 禁言所有用户
 export function bandAlluser(id, data) {
	 return request.post('store/merchant/live_room/switch_banned_status/'+id, data)
 }
 // 记录随手拍直播消息
 export function recordLiveInfo(id,data) {
	 return request.post('store/merchant/live_room/update_cache_info/'+id, data)
 }
 // 设置入口商品
 export function setenterGoods(id, data) {
	 return request.post(`store/merchant/product/setEntranceProduct/${id}`, data)
 }
 // 查询是否为入口商品
 export function findenterGoods(id) {
	 return request.get(`store/product/isWechatEntranceProduct/${id}`, {}, {
		 noAuth: true
	 })
 }
 // 选择历史微信分类
 export function gethistorywxcate() {
	 return request.get('store/merchant/getWechatCatList')
 }