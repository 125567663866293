<template>
  <div style="min-height:100vh;">
    <CommonHeader title="支付密码" />
    <div class="upload-container">
      <div class="item">
        <div class="l">手机号码</div>
        <div class="c">
          <div class="flex-one">{{ phone }}</div>
        </div>
      </div>
      <div class="item">
        <div class="l">
          <input
            type="number"
            maxlength="4"
            v-model="code"
            bindinput="changecode"
            placeholder="请输入您的短信验证码"
            placeholder-style="color: #999"
          />
        </div>
        <div
          class="r"
          :class="disabled ? 'count-down' : ''"
          @click="codeAction"
        >
          {{ text }}
        </div>
      </div>
      <div class="item">
        <div class="l">
          <input
            type="number"
            maxlength="6"
            :password="!showPassword"
            placeholder="请输入6位纯数字支付密码"
            placeholder-style="color: #999"
            v-model="password"
            @blur="blurPassword(1)"
          />
        </div>
        <div class="r1">
          <image
            @click="showPasswordAction"
            v-if="showPassword"
            src="https://artimg2.artart.cn/image/20210225/2fe9e2e22c7412592a9551334dfa4c63.png"
          ></image>
          <image
            @click="showPasswordAction"
            v-else
            src="https://artimg2.artart.cn/image/20210225/0cefa636bd52269ddf9254e8bd443734.png"
          ></image>
        </div>
      </div>
      <div class="item">
        <div class="l">
          <input
            type="number"
            maxlength="6"
            :password="!showPassword"
            placeholder="请确认输入6位纯数字支付密码"
            v-model="passwordTwo"
            placeholder-style="color: #999"
            @blur="blurPassword(2)"
          />
        </div>
      </div>
    </div>
    <div class="txt font-color-price" v-if="numErr">
      <template v-if="numErr == 1">支付密码为6位纯数字，请重新输入</template>
      <template v-else-if="numErr == 2"
        >两次支付密码不一致，请重新输入</template
      >
      <template v-else-if="numErr == 3">原密码为6位纯数字</template>
    </div>
    <div
      :class="'btn ' + (code && password && passwordTwo ? 'finfish' : '')"
      :style="rightBtnStyle"
      @click="save"
    >
      提交
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, ref, onBeforeMount, watch } from "vue";
import { sendSms, setPassword } from "@/api/seller";
import { getInfo } from "@/api/user";
import { Dialog, Toast } from "vant";
export default {
  name: "PayPassword",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const phone = ref("");
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    const code = ref("");
    const password = ref("");
    const passwordTwo = ref("");
    const numErr = ref(0);
    const showPassword = ref(false);
    const saveLock = ref(false);
    const goodder = ref("");
    const isSetPayPass = ref(false);
    const oldPassword = ref("");
    const disabled = ref(false);
    const text = ref("获取验证码");
    getInfo().then((res) => {
      if (res.data.isSetPayPass) {
        isSetPayPass.value = true;
      }
      phone.value = res.data.phone;
    });

    const sendCode = () => {
      if (disabled.value) return;
      disabled.value = true;
      let n = 60;
      text.value = "剩余 " + n + "s";
      const run = setInterval(() => {
        n = n - 1;
        if (n < 0) {
          clearInterval(run);
        }
        text.value = "剩余 " + n + "s";
        if (text.value < "剩余 " + 0 + "s") {
          disabled.value = false;
          text.value = "重新获取";
        }
      }, 1000);
    };
    const blurPassword = (key) => {
      if (key == 1) {
        if (isNaN(password.value) || (password.value+'').length != 6) {
          numErr.value = 1;
        } else if (
          (passwordTwo.value+'').length &&
          password.value != passwordTwo.value
        ) {
          numErr.value = 2;
        } else {
          numErr.value = 0;
        }
      } else if (key == 2) {
        if (isNaN(passwordTwo.value) || (passwordTwo.value+'').length != 6) {
          numErr.value = 1;
        } else if (
          (password.value+'').length &&
          passwordTwo.value != password.value
        ) {
          numErr.value = 2;
        } else {
          numErr.value = 0;
        }
      }
    };
    const save = () => {
      if (saveLock.value) return false;
      saveLock.value = true;
      if (!code.value) {
        saveLock.value = false;
        Toast("请输入验证码");
        return;
      }

      if (
        !password.value ||
        isNaN(password.value) ||
        (password.value+'').length != 6
      ) {
        saveLock.value = false;
        Toast("支付密码为6位纯数字，请重新输入");
        return;
      }
      if (password.value != passwordTwo.value) {
        saveLock.value = false;
        Toast("两次支付密码不一致，请重新输入");
        return;
      }
      const data = {
        sms_code: code.value,
        pay_pass: password.value,
        type: 1,
      };
      if (isSetPayPass.value) {
        data.type = 2;
        data.old_pay_pass = oldPassword.value;
      }
      setPassword(data)
        .then((res) => {
          saveLock.value = false;
          Toast(res.message);
          router.back();
        })
        .catch((res) => {
          console.log(res);
          saveLock.value = false;
          Toast(res.message);
        });
    };
    const showPasswordAction = (res) => {
      showPassword.value = !showPassword.value;
    };
    const codeAction = () => {
      if (disabled.value) return false;
      sendSms()
        .then((res) => {
          Toast(`短信验证码已发送至尾号${phone.value.slice(-4)}手机`);
          sendCode();
        })
        .catch((res) => {
          console.log(res);
          Toast(res);
        });
    };
    return {
      phone,
      rightBtnStyle,
      code,
      password,
      passwordTwo,
      numErr,
      showPassword,
      saveLock,
      goodder,
      isSetPayPass,
      oldPassword,
      disabled,
      text,

      blurPassword,
      sendCode,
      save,
      showPasswordAction,
      codeAction
    };
  },
};
</script>
<style lang="scss" scoped>
.upload-container {
  margin: 30px 30px 0 30px;
  padding: 0 30px;
  background: #fff;
}

.upload-container .item {
  height: 100px;
  display: flex;
  align-items: center;
  border-top: 2px solid #eee;
}

.upload-container .item:first-child {
  border-top: none;
}

.upload-container .l {
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex: 1;
}

.upload-container input {
  flex: 1;
  font-size: 28px;
  font-weight: 400;
}

.upload-container image {
  width: 30px;
  height: 30px;
  display: block;
  margin-left: 10px;
}

.upload-container .c {
  flex: 1;
  display: flex;
  margin-left: 30px;
  font-size: 28px;
}

.upload-container input {
  flex: 1;
  text-align: left;
  font-size: 28px;
  border: none;
}

.upload-container .flex-one {
  flex: 1;
  text-align: right;
}

.upload-container .c-input {
  font-weight: bold;
}

.upload-container .r {
  width: 240px;
  height: 60px;
  border: 2px solid #000;
  border-radius: 30px;
  font-size: 28px;
  text-align: center;
  line-height: 58px;
}

.upload-container .r.count-down {
  opacity: 0.2;
}

.upload-container .r1 {
  width: 32px;
  height: 32px;
}

.upload-container img {
  width: 32px;
  height: 32px;
  display: block;
}

.txt {
  height: 60px;
  line-height: 60px;
  margin: 0 30px;
  margin-top: 20px;
  text-align: center;
}

.btn {
  margin: 30px 30px 30px 30px;
  text-align: center;
  height: 80px;
  line-height: 80px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 50px;
  font-size: 32px;
}

.btn.finfish {
  background: #000;
}
.font-color-price {
  color: #b8202c !important;
}
</style>